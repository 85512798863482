import {ONBOARDING_VERSION} from 'configs';

export const ONBOARDING_IMPORT_SETTINGS = [
  {
    target: '.onboard-ftp-settings',
    title: 'Global FTP Connection Settings',
    content:
      'Manage your FTP connection to import certification body or certification data using a single FTP connection.',
    placement: 'right',
  },
  {
    target: '.onboard-api-integration',
    title: 'API Integration',
    content:
      'A single API key allows the import of certification body or certification data.',
    placement: 'right',
  },
  {
    target: '.onboard-global-mapping',
    title: 'Global Field and Data Mapping',
    content:
      'Manage field and data mapping for Certification Body and Certification data.',
    placement: 'right',
  },
  {
    target: '.onboard-auto-import-settings',
    title: 'Skip Errors',
    content:
      'Clean data can be automatically imported while skipping issues to optimize your time.',
    placement: 'right',
  },
  {
    target: '.onboard-template-versioning',
    title: 'Template Versioning',
    content:
      'Update your data to the most recent version at your earliest convenience. Details regarding changes are provided for your reference.',
    placement: 'right',
    path: 'template-versioning/certification-body',
  },
];

const ONBOARDING_TEMPLATE_VERSION_AB = [
  {
    target: '.onboard-template-version-available',
    title: 'Templates Available',
    content:
      'Use these tabs to select between Certification Body and Certifications.',
    placement: 'right',
  },
  {
    target: '.onboard-template-version',
    title: 'Latest Template Version',
    content:
      'This section allows you to verify which template version you are using, and whether it is current or outdated. It is best practice to always use the most up-to-date version to maintain data integrity.',
    placement: 'left',
  },
  {
    target: '.onboard-preview-data-format',
    title: 'Template Preview',
    content:
      'This latest template preview offers the format and structure to assist you in transforming your data accordingly.',
    placement: 'left',
  },
  {
    target: '.onboard-template-changes',
    title: 'Template Changes',
    content:
      'Familiarize yourself with any changes to ensure a smooth transition. Review for potential new or updated fields to avoid issues during the transition process.',
    placement: 'right',
  },
  {
    target: '.onboard-download-template',
    title: 'Download the Latest Template',
    content:
      'Upon familiarizing yourself with the updates, please format and collate your data into the latest provided template.',
    placement: 'left',
  },

  {
    target: '.onboard-update-now',
    title: 'Update Now',
    content:
      'Upon completion of data compilation and file formatting, proceed by selecting the "Update Now" option.',
    placement: 'left',
  },
];

const ONBOARDING_TEMPLATE_VERSION_CB = [
  {
    target: '.onboard-template-version',
    title: 'Latest Template Version',
    content:
      'This section allows you to verify which template version you are using, and whether it is current or outdated. It is best practice to always use the most up-to-date version to maintain data integrity.',
    placement: 'left',
  },
  {
    target: '.onboard-preview-data-format',
    title: 'Template Preview',
    content:
      'This latest template preview provides the format and structure for transforming your data accordingly.',
    placement: 'left',
  },
  {
    target: '.onboard-template-changes',
    title: 'Template Changes',
    content:
      'Familiarize yourself with any changes to ensure a smooth transition. Review for potential new or updated fields to avoid issues during the transition process.',
    placement: 'right',
  },
  {
    target: '.onboard-download-template',
    title: 'Download the latest template',
    content:
      'Upon familiarizing yourself with the updates, please format and collate your data into the latest provided template.',
    placement: 'left',
  },
  {
    target: '.onboard-update-now',
    title: 'Update Now!',
    content:
      'Upon completion of data compilation and file formatting, proceed by selecting the "Update Now" option.',
    placement: 'left',
  },
];

const ONBOARDING_TEMPLATE_VERSIONING_NAV = [
  {
    target: '.onboard-template-versioning',
    title: 'Template Versioning',
    content:
      'Template Versioning is now available in the Import Settings page. Update your data to the most recent version at your earliest convenience',
    placement: 'right',
  },
];

const ONBOARDING_CB_OVERVIEW = [
  {
    target: '.onboard-cb-details',
    title: 'Certification Body',
    content:
      'Take a look at the account details of the Certification Body, with the new additional fields, you may now include your public contact information.',
    placement: 'right',
  },
  {
    target: '.onboard-cb-standards-schemes',
    title: 'Accreditation Information',
    content:
      "Here you'll get to know the list of accredited standards and schemes, including the Technical Sectors and Country / Economy",
    placement: 'right',
  },
  {
    target: '.onboard-cb-sectors',
    title: 'Authorized Sector Industry',
    content:
      'Overall, the Technical Sectors display accredited sectors along with their respective standards and schemes.',
    placement: 'right',
  },
  {
    target: '.onboard-cb-locations',
    title: 'Accrediting Locations',
    content:
      "Office directories have been renamed to 'Key Locations,' representing sites eligible for accreditation.",
    placement: 'right',
  },
  {
    target: '.onboard-cb-countries',
    title: 'Accredited Country / Economy',
    content:
      'High-level Country / Economy display qualified locations that can be accredited by the Standards and Schemes assigned.',
    placement: 'right',
  },
];

const ONBOARDING_CB_STANDARDS_DETAIL = [
  {
    target: '.onboard-standard-detail',
    title: 'Enhanced Accreditation',
    content:
      'Standard and Scheme now have dedicated detail pages with listing of accredited Key Locations, Technical Sectors, and Country / Economy information.',
    placement: 'right',
  },
  {
    target: '.onboard-standard-location',
    title: 'Accredited Location',
    content:
      'Accreditation can now be linked to the location from which standards and schemes may be issued from.',
    placement: 'right',
  },
  {
    target: '.onboard-standard-sectors',
    title: 'Technical Sectors',
    content:
      'The Technical Sectors can now be associated with Standards and Schemes to define the extent of Accreditation.',
    placement: 'right',
  },
  {
    target: '.onboard-standard-countries',
    title: 'Accredited Country / Economy',
    content:
      'The Country / Economy where an accreditation can be issued can now be linked to Standards and Schemes.',
    placement: 'right',
  },
];

const ONBOARDING_CE_DETAIL = [
  {
    target: '.onboard-ce-detail',
    title: 'Certified Entity',
    content:
      'Take a look at the details of the certified entity with newly added fields.',
    placement: 'right',
  },
  {
    target: '.onboard-ce-website',
    title: 'Additional New Fields',
    content: 'New fields include Website',
    placement: 'top',
  },
  {
    target: '.onboard-ce-legal-identifiers',
    title: 'Additional New Fields: Legal Identifiers',
    content:
      "We've introduced legal identifiers to help maintain the integrity of your data. These include VAT, Tax ID, and DUNS numbers.",
    placement: 'top',
  },
  {
    target: '.onboard-ce-address',
    title: 'Additional New Fields',
    content: 'and the Business Registered Address Details.',
    placement: 'top',
  },
  {
    target: '.onboard-ce-cert',
    title: 'Certifications Listing',
    content:
      'Listing page that redirects to the certifications of this certified entity.',
    placement: 'right',
  },
  {
    target: '.onboard-ce-sites',
    title: 'Certified Entity Sites',
    content:
      'This displays all the locations of this entity that have been officially accredited for meeting specific standards, criteria, and regulations.',
    placement: 'right',
  },
];

const ONBOARD_CE_SITES = [
  {
    target: '.onboard-ce-sites',
    title: 'Certified Entity Sites',
    content:
      'This displays all the locations of this entity that have been officially accredited for meeting specific standards, criteria, and regulations.',
    placement: 'right',
  },
  {
    target: '.onboard-ce-sites-add',
    title: 'Add Certified Entity Sites',
    content:
      'Manually add a location for Certificates to use by providing necessary details.',
    placement: 'left',
  },
  {
    target: '.onboard-ce-sites-cert',
    title: 'Assign to a Certification',
    content:
      'After adding the site, you can assign it to a certificate to designate it as a Certified Site. Otherwise, it will remain listed as a site of the Entity privately within your dashboard.',
    placement: 'left',
  },
];

const ONBOARDING_CERT_LIST = [
  {
    target: '.onboard-cert-add',
    title: 'Add Certification',
    content:
      "Certifications can now be added directly here. We've also introduced new fields and an updated process for adding sites.",
    placement: 'left',
  },
];

const ONBOARDING_CE_LIST = [
  {
    target: '.onboard-ce-first-row',
    title: 'What’s New?',
    content:
      'Check out some adjustments made in the interface of Certified Entity for the alignment in data structure.',
    placement: 'left',
  },
];

const ONBOARD_CERT_ADD = [
  {
    target: '.onboard-cert-ce-input',
    title: 'New Field: Certified Entity',
    content:
      'The Certified Entity field allows you to select the entity that holds the certification you are about to create.',
    placement: 'right',
  },
  {
    target: '.onboard-cert-flexible-scope-input',
    title: 'New Field: Flexible Scope',
    content:
      'A "Flexible Scope" is an optional field where you can provide additional information about the certification scope.',
    placement: 'right',
  },
  {
    target: '.onboard-cert-sites-input',
    title: 'Certification Sites',
    content:
      'You can add sites that are also eligible to hold the certificate. You may select sites from your current entity or from other entities associated with the certificate.',
    placement: 'right',
  },
  {
    target: '.onboard-cert-ce-site-input',
    title: 'Certification with Multiple Entities',
    content:
      'If your certificate is issued to multiple entities, you can use the Certified Entity field to select entities other than the one you are currently in.',
    placement: 'right',
  },
];

const ONBOARDING_CB_LIST = [
  {
    target: '.onboard-cb-first-row',
    title: 'Certification Bodies',
    content:
      'Take a look at the updated interface for the certification body page and its accreditation.',
    placement: 'left',
  },
];

const ONBOARD_CB_STANDARDS_LIST = [
  {
    target: '.onboard-cb-standards-first-row',
    title: 'Standards and Schemes',
    content:
      'Check out the updated details of the Standard and Scheme page, along with its associated scope.',
    placement: 'left',
  },
];

export const DEFAULT_VALUE = {
  onboarding_version: ONBOARDING_VERSION,
  import_settings: true,
  template_versioning: true,
  template_versioning_nav: true,
  certification_bodies: true,
  certification_bodies_list: true,
  certification_bodies_standards: true,
  certification_bodies_standards_list: true,
  certified_entities_list: true,
  certified_entities: true,
  certified_entities_sites: true,
  certifications_list: true,
  certifications_add: true,
};

// export const ONBOARDING_INITIAL_STEPS = {
//   certification_body: ONBOARDING_CB_LIST,
//   certification_body_standards: ONBOARD_CB_STANDARDS_LIST,
// };

export const ONBOARDING_STEPS = {
  import_settings: ONBOARDING_IMPORT_SETTINGS,
  certification_body_standards: ONBOARDING_CB_STANDARDS_DETAIL,
  certification_body: ONBOARDING_CB_OVERVIEW,
  certification_bodies_list: ONBOARDING_CB_LIST,
  certification_bodies_standards_list: ONBOARD_CB_STANDARDS_LIST,
  template_versioning_ab_v1: ONBOARDING_TEMPLATE_VERSION_AB,
  template_versioning_ab_v2_cb_only: ONBOARDING_TEMPLATE_VERSION_AB.filter(
    (v) => '.onboard-update-now' !== v.target
  ),
  template_versioning_ab_v2: ONBOARDING_TEMPLATE_VERSION_AB.filter(
    (v) =>
      '.onboard-update-now' !== v.target && '.onboard-update-final' !== v.target
  ),
  template_versioning_cb_v1: ONBOARDING_TEMPLATE_VERSION_CB,
  template_versioning_cb_v2: ONBOARDING_TEMPLATE_VERSION_CB.filter(
    (v) => '.onboard-update-now' !== v.target
  ),
  template_versioning_nav: ONBOARDING_TEMPLATE_VERSIONING_NAV,
  certified_entities: ONBOARDING_CE_DETAIL,
  certified_entities_list: ONBOARDING_CE_LIST,
  certified_entities_sites_ab: ONBOARD_CE_SITES.filter(
    (v) => '.onboard-ce-sites-add' !== v.target
  ),
  certified_entities_sites: ONBOARD_CE_SITES,
  certifications_list: ONBOARDING_CERT_LIST,
  certifications_add: ONBOARD_CERT_ADD,
};
