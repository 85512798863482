import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import {isNumber, toNumber, isNaN} from 'lodash/lang';

export const setDayJs = () => {
  dayjs.extend(duration);
  dayjs.extend(relativeTime);
};

export const date = (value, format = 'YYYY-MM-DD') => {
  let result;

  if (!value) return 'N/A';

  //  toNumber()
  //  1540262852 -> 1540262852
  //  '1540262852' -> 1540262852
  //  '2009-12-12' -> NaN
  let numberValue = toNumber(value);

  if (!isNumber(numberValue) || isNaN(numberValue)) {
    result = dayjs(value).format(format);
  } else if (isNumber(numberValue) && format === 'fromNow') {
    result = dayjs(numberValue * 1000).fromNow();
  } else if (isNumber(numberValue)) {
    result = dayjs(numberValue * 1000).format(format);
  }

  return result;
};

export const dateToFormat = (value, format = 'YYYY-MM-DD') => {
  if (!value) return 'N/A';

  return dayjs(value).format(format);
};

export function dateDiff(dateEarlier, dateLater) {
  const one_day = 60 * 60 * 24;
  return Math.round((dateLater - dateEarlier) / one_day);
}
