import Loadable from 'components/Loadable';

import {ENABLE_CERT_READ, ENABLE_CERT_SITES} from 'configs/enable-features';

const certV1 = [
  {
    exact: true,
    path: '/certifications/v1',
    component: Loadable({
      loader: () => import('../views/certifications/v1/list'),
    }),
  },
  /*
    {
      exact: true,
      path: '/certifications/create',
      component: Loadable({
        loader: () => import('../views/certifications/create'),
      }),
    },
    */
  {
    path: '/certifications/v1/:certId',
    component:
      ENABLE_CERT_READ &&
      Loadable({
        loader: () => import('../views/certifications/v1/view'),
      }),
    routes: [
      {
        exact: true,
        path: '/certifications/v1/:certId',
        component: Loadable({
          loader: () => import('../views/certifications/v1/view/details'),
        }),
      },
      {
        exact: true,
        path: '/certifications/v1/:certId/sites',
        component:
          ENABLE_CERT_SITES &&
          Loadable({
            loader: () => import('../views/certifications/v1/view/sites'),
          }),
      },
    ],
  },
];

export default certV1;
