import Loadable from 'components/Loadable';

import {
  ENABLE_CE_READ,
  ENABLE_CE_CREATE,
  ENABLE_CERT_CREATE,
} from 'configs/enable-features';

const ceV2 = [
  {
    exact: true,
    path: '/certified-entities/v2',
    component: Loadable({
      loader: () => import('../views/certified-entities/v2/list'),
    }),
  },
  {
    exact: true,
    path: '/certified-entities/v2/create',
    component:
      ENABLE_CE_CREATE &&
      Loadable({
        loader: () => import('../views/certified-entities/v2/create'),
      }),
  },
  {
    path: '/certified-entities/v2/:ceId',
    component:
      ENABLE_CE_READ &&
      Loadable({
        loader: () => import('../views/certified-entities/v2/view'),
      }),
    routes: [
      {
        exact: true,
        path: '/certified-entities/v2/:ceId',
        component: Loadable({
          loader: () => import('../views/certified-entities/v2/view/details'),
        }),
      },
      {
        exact: true,
        path: '/certified-entities/v2/:ceId/certifications',
        component: Loadable({
          loader: () =>
            import('../views/certified-entities/v2/view/certifications/list'),
        }),
      },
      {
        exact: true,
        path: '/certified-entities/v2/:ceId/sites',
        component: Loadable({
          loader: () => import('../views/certified-entities/v2/view/sites'),
        }),
      },
      {
        exact: true,
        path: '/certified-entities/v2/:ceId/certifications/create',
        component:
          ENABLE_CERT_CREATE &&
          Loadable({
            loader: () =>
              import(
                '../views/certified-entities/v2/view/certifications/create'
              ),
          }),
      },
    ],
  },
];

export default ceV2;
