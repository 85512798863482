import styles from './styles.module.scss';

import React from 'react';
import {get} from 'lodash/object';
import {isEmpty} from 'lodash/lang';
import {NavLink} from 'react-router-dom';
import {Badge} from 'reactstrap';

import {useAuth} from 'contexts/AuthContext';
import {
  COMPANY_TYPE_LEVEL,
  DOCUMENTATION_LANDING_URL,
  IS_DEFAULT,
} from 'configs';

function PrivateSidebarNavsItem(props) {
  const {nav} = props;
  const {to, href, content} = nav;

  return (
    <li>
      {href ? (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {content}
        </a>
      ) : (
        <NavLink to={to}>{content}</NavLink>
      )}
    </li>
  );
}

function PrivateSidebarNavs(props) {
  const {value, hr = true} = props;
  const {header, navs} = value;

  if (isEmpty(navs)) return false;

  return (
    <>
      <div className="my-3">
        {!!header && <small>{header}</small>}
        <ul>
          {(navs || []).map((nav, index) => (
            <PrivateSidebarNavsItem key={index} nav={nav} />
          ))}
        </ul>
      </div>
      {hr && <hr />}
    </>
  );
}

function PrivateSidebar() {
  const auth = useAuth();

  const companyType = get(auth, 'account.company.company_type', '');
  const companyLevel = COMPANY_TYPE_LEVEL[companyType];

  const isDefault = IS_DEFAULT[get(auth, 'account.company.is_default', 0)];
  const isAB = companyLevel === COMPANY_TYPE_LEVEL['ab'];
  const isBiggerThanAB = companyLevel >= COMPANY_TYPE_LEVEL['ab'];
  const isCB = companyLevel === COMPANY_TYPE_LEVEL['cb'];
  const isBiggerThanCB = companyLevel >= COMPANY_TYPE_LEVEL['cb'];
  const isCE = companyLevel === COMPANY_TYPE_LEVEL['ce'];
  const isMNCB = companyLevel === COMPANY_TYPE_LEVEL['mncb'];

  const overviews = {
    header: 'Overview',
    navs: [{to: '/dashboard', content: 'Dashboard'}],
  };

  const dataListings = {
    header: 'Data Listings',
    navs: [
      (isBiggerThanAB || isMNCB) && {
        to: '/certification-bodies',
        content: 'Certification Bodies',
      },
      (isAB || isCB || isMNCB) && {
        to: '/certified-entities',
        content: 'Certified Entities',
      },
      // will revert back this one once MNCB is ready for data source
      // ((isAB && hasPermission) || isCB || isCE || isMNCB) && {
      //   to: '/certifications',
      //   content: 'Certifications',
      // },
      (isAB || isCB || isMNCB) && {
        to: '/certifications',
        content: 'Certifications',
      },
      isDefault &&
        isBiggerThanCB &&
        isAB && {
          to: '/ab-standards',
          content: 'Standards and Schemes',
        },
      isDefault &&
        isBiggerThanCB &&
        isCB && {
          to: '/cb-standards',
          content: (
            <React.Fragment>
              Standards and Schemes
              <Badge color="danger" className="ml-2">
                New
              </Badge>
            </React.Fragment>
          ),
        },
    ].filter((v) => v),
  };

  const dataManagement = {
    header: 'Data Management',
    navs: [
      isAB && {
        to: '/cb-upload',
        content: 'Import Certification Body',
      },
      (isAB || isCB || isMNCB) && {
        to: '/import-management',
        content: (
          <React.Fragment>
            {isAB ? 'Import Certifications' : 'Import'}
          </React.Fragment>
        ),
      },
      {
        to: '/import-settings',
        content: (
          <React.Fragment>
            Import Settings
            <Badge color="danger" className="ml-2">
              New
            </Badge>
          </React.Fragment>
        ),
      },
      // {to: '/export', content: 'Export'}
    ].filter((v) => v),
  };

  const settings = {
    header: 'Settings',
    navs: [
      isDefault &&
        isBiggerThanCB && {
          to: '/branches',
          content: 'Accounts',
        },
      {
        to: '/users',
        content: 'Users',
      },
      isAB && {
        to: '/ab-profile',
        content: 'Accreditation Body Profile',
      },
      isCB && {
        to: '/cb-profile',
        content: 'Certification Body Profile',
      },
      isCE && {
        to: '/ce-profile',
        content: 'Company Profile',
      },
      {to: '/settings', content: 'Settings'},
    ].filter((v) => v),
  };

  const others = {
    navs: [
      // MNCB doesn't have default type account. We should skip checking default.
      (isMNCB || (isDefault && isBiggerThanCB)) && {
        href: DOCUMENTATION_LANDING_URL,
        content: 'User Guide',
      },
    ].filter((v) => v),
  };

  return (
    <nav className={styles['private-sidebar']}>
      <PrivateSidebarNavs value={overviews} />

      {/* Will remove this conditions once data source in ce and certifications is done */}
      {(isAB || isCB || isMNCB) && (
        <React.Fragment>
          <PrivateSidebarNavs value={dataListings} />
        </React.Fragment>
      )}

      {((isAB && isDefault) || (isCB && isDefault) || isMNCB) && (
        <React.Fragment>
          <PrivateSidebarNavs value={dataManagement} />
        </React.Fragment>
      )}

      <PrivateSidebarNavs
        value={settings}
        hr={isMNCB || (isDefault && isBiggerThanCB)}
      />

      <PrivateSidebarNavs value={others} hr={false} />
    </nav>
  );
}

export default PrivateSidebar;
