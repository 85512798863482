//import {keys, pick} from 'lodash/object';

import {pick} from 'lodash/object';

export const COMPANY_TYPE = {
    ab: 'ab',
    cb: 'cb',
    ce: 'ce',
    company: 'company',
    mncb: 'mncb',
  },
  COMPANY_TYPE_LABEL = {
    [COMPANY_TYPE.ab]: 'Accreditation Body',
    [COMPANY_TYPE.cb]: 'Certification Body',
    [COMPANY_TYPE.ce]: 'Certified Company',
    [COMPANY_TYPE.company]: 'Company',
    [COMPANY_TYPE.mncb]: 'MNCB',
  };

// History Type

export const HISTORY_TYPE = {
    certification: 'certification',
    company: 'company',
  },
  HISTORY_TYPE_LABEL = {
    [HISTORY_TYPE.certification]: 'Certification',
    [HISTORY_TYPE.company]: 'Company',
  };

export const NOTIFICATION_TYPE = {
  alert: 'alert',
  email: 'email',
};

// For AB and CB
// Filter by message type to identify which company is the sender or receiver

export const ENQUIRY_MESSAGE_TYPE = {
  received: 'Received',
  sent: 'Sent',
};

export const ENQUIRY_TYPE = {
  accreditationConfirmation: 'Accreditation Confirmation',
  certificationConfirmation: 'Certification Confirmation',
  accreditationEnquiry: 'Accreditation enquiry',
  requestQuotation: 'Request Quotation',
  support: 'Support',
  feedback: 'Feedback',
};

export const AB_ENQUIRY_TYPE = pick(ENQUIRY_TYPE, [
  'accreditationConfirmation',
  'certificationConfirmation',
  'accreditationEnquiry',
  'support',
  'feedback',
]);

export const CB_ENQUIRY_TYPE = pick(ENQUIRY_TYPE, [
  'certificationConfirmation',
  'requestQuotation',
  'support',
  'feedback',
]);

export const CE_ENQUIRY_TYPE = pick(ENQUIRY_TYPE, [
  'certificationConfirmation',
  'support',
  'feedback',
]);

export const SIGNATURE_TYPE = {
  type: 'type',
  draw: 'draw',
  image: 'image',
};

export const SIGNATURE_FONT_TYPE = {
  pinyon: 'pinyon',
  'great-vibes': 'great-vibes',
  'ms-madi': 'ms-madi',
};

export const CONTACT_SUPPORT_CATEGORIES = {
  sandbox_account: 'Sandbox Account',
  enquiry: 'Enquiry',
  support: 'Support',
};

export const CONTACT_SUPPORT_ACCOUNT_TYPE = {
  'Certification Body': 'Certification Body',
  'Accreditation Body': 'Accreditation Body',
};

export const SITE_TYPE = {
  main: 'Main Site',
  add: 'Additional Site',
};
