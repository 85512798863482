import styles from './styles.module.scss';

import React from 'react';
import dayjs from 'dayjs';
import {Row} from 'reactstrap';

const Copyright = () => {
  const year = dayjs().format('YYYY');

  return (
    <div className="text-center">
      Copyright &copy; {year} IAF CertSearch. All Right Reserved
    </div>
  );
};

const PageFooter = () => {
  return (
    <footer className={styles['page__footer']}>
      <div className="container">
        <Row className="align-items-center pb-3">
          <div className="col order-2 order-lg-1">
            <Copyright />
          </div>
        </Row>

        <div className="text-center">
          Powered by{' '}
          <strong>
            <a
              href="https://www.qualitytrade.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              QualityTrade
            </a>
          </strong>
        </div>
      </div>
    </footer>
  );
};

export default PageFooter;
