import Loadable from 'components/Loadable';

const contactSupport = {
  exact: true,
  path: '/contact-support',
  component: Loadable({
    loader: () => import('../views/contact-support'),
  }),
};

export default contactSupport;
