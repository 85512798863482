import urlJoin from 'url-join';

export const IS_STAGE_DEV =
  process.env.REACT_APP_STAGE === 'development' ||
  process.env.REACT_APP_STAGE === 'alpha' ||
  process.env.REACT_APP_STAGE === 'staging' ||
  process.env.REACT_APP_STAGE === 'beta';
export const IS_ENV_DEV = process.env.NODE_ENV === 'development';
export const IS_ENV_PROD = process.env.NODE_ENV === 'production';
export const ENV = process.env.NODE_ENV;
export const STAGE = process.env.REACT_APP_STAGE;
export const HOST = window.location.protocol + window.location.host;
export const COOKIE_PREFIX = process.env.REACT_APP_COOKIE_PREFIX;

//
// URL
export const PUBLIC_URL = process.env.PUBLIC_URL || '/';
export const DOMAIN = process.env.REACT_APP_DOMAIN;
export const API_URL = process.env.REACT_APP_API_URL;
export const API_VU_URL = process.env.REACT_APP_API_VU_URL;
export const CDN_URL = process.env.REACT_APP_CDN_URL;
export const CDN_FILE_URL = process.env.REACT_APP_CDN_FILE_URL;

export const CDN_IMAGE_URL = urlJoin(CDN_URL, 'r'); // Global resources.
export const CDN_PAGE_RESOURCE_URL = urlJoin(CDN_IMAGE_URL, 'p'); // Local resources for each page.

export const USER_GUIDE_TEMPLATE = process.env.REACT_APP_USER_GUIDE;
export const PROGRESS_GUIDE = process.env.REACT_APP_PROGRESS_GUIDE;

const Environment = {
  IS_STAGE_DEV: IS_STAGE_DEV,
  IS_ENV_DEV: IS_ENV_DEV,
  IS_ENV_PROD: IS_ENV_PROD,
  COOKIE_PREFIX: COOKIE_PREFIX,
  PUBLIC_URL: PUBLIC_URL,
  DOMAIN: DOMAIN,
  API_URL: API_URL,
  API_VU_URL: API_VU_URL,
  CDN_URL: CDN_URL,
  CDN_FILE_URL: CDN_FILE_URL,
  CDN_IMAGE_URL: CDN_IMAGE_URL,
  CDN_PAGE_RESOURCE_URL: CDN_PAGE_RESOURCE_URL,
  USER_GUIDE_TEMPLATE,
  PROGRESS_GUIDE,
};

export default Environment;
