import React, {createContext, useContext, useEffect, useState} from 'react';
import {ACTIONS} from 'react-joyride';
import LocalStorage from 'utils/localStorage';
import {useAuth} from './AuthContext';
import {COMPANY_TYPE} from 'configs/types';
import {DEFAULT_VALUE} from 'configs/onboarding';
import {isEmpty} from 'lodash';
import {ONBOARDING_VERSION} from 'configs';

function templateUpdateGuideHandler(companyType) {
  let value;

  switch (companyType) {
    case COMPANY_TYPE.ab:
    case COMPANY_TYPE.cb:
    case COMPANY_TYPE.mncb: {
      value = DEFAULT_VALUE;
      break;
    }
    default:
      value = {};
  }

  return value;
}

const OnboardContext = createContext({});

function OnboardProvider({children}) {
  const {companyType} = useAuth();

  const storedData = LocalStorage.templateUpdateGuide;
  const [onboardState, setOnboardState] = useState({
    running: false,
    startModalOpen: true, // opens initially
    endModalOpen: false,
    redirect: null,
  });

  function startOnboarding() {
    setOnboardState((prev) => ({
      ...prev,
      startModalOpen: false,
      running: true,
    }));
  }

  function toggleStartModal() {
    setOnboardState((prev) => ({
      ...prev,
      startModalOpen: !prev.startModalOpen,
    }));
  }

  function toggleEndModal(type) {
    LocalStorage.templateUpdateGuide = {
      ...storedData,
      [type]: false,
    };
    setOnboardState((prev) => ({
      ...prev,
      endModalOpen: !prev.endModalOpen,
    }));
  }

  function toggleSkip(type) {
    LocalStorage.templateUpdateGuide = {
      ...storedData,
      [type]: false,
    };
    setOnboardState((prev) => ({
      ...prev,
      startModalOpen: !prev.startModalOpen,
    }));
  }

  function handleJoyrideCallback(data) {
    const {action} = data;

    if (action === ACTIONS.RESET) {
      setOnboardState((prev) => ({
        ...prev,
        running: false,
        endModalOpen: !prev.endModalOpen,
      }));
    }
  }

  useEffect(() => {
    if (isEmpty(storedData)) {
      const guide = templateUpdateGuideHandler(companyType);
      LocalStorage.templateUpdateGuide = guide;
    } else {
      const {onboarding_version} = storedData || {};

      // every single time that there is added variable on DEFAULT_VALUE we need to constantly update env on ONBOARDING_VERSION this will update the local storage variable
      if (onboarding_version !== ONBOARDING_VERSION) {
        const guide = templateUpdateGuideHandler(companyType);

        LocalStorage.templateUpdateGuide = {
          ...guide,
          ...LocalStorage.templateUpdateGuide,
          onboarding_version: ONBOARDING_VERSION,
        };
      }
    }
  }, [companyType]);

  return (
    <OnboardContext.Provider
      value={{
        onboardState,
        data: storedData,
        toggleSkip,
        toggleEndModal,
        startOnboarding,
        toggleStartModal,
        callback: handleJoyrideCallback,
        onboardStateChange: setOnboardState,
      }}
    >
      {children}
    </OnboardContext.Provider>
  );
}

const useOnboard = () => useContext(OnboardContext);

export {OnboardContext, OnboardProvider, useOnboard};
