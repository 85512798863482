import http from 'utils/http';
import {API_VU_URL} from 'configs';

export const httpVu = (method = 'GET', url, data, options = {}) =>
  http(method, url, data, {...options, baseURL: API_VU_URL});

/**
 *
 * @param data: email, password, remember, secret
 */
export const login = (data) => {
  return httpVu('POST', 'auth/login', data);
};

export const post2FA = (data) => {
  return httpVu('POST', 'auth/2fa', data);
};

export const loginOAuth = (data) => {
  return httpVu('POST', 'auth/oauth', data);
};

export const getAuthSession = (sessionId) => {
  return httpVu('GET', `auth/session/${sessionId}`);
};

export const logout = () => {
  return httpVu('POST', 'auth/logout');
};

export const forgot = (params) => {
  return httpVu('POST', 'auth/forgot', params);
};

export const reset = (params) => {
  return httpVu('POST', 'auth/reset', params);
};

export const getAuthResetToken = (token) => {
  return httpVu('GET', `auth/reset/${token}`);
};

export const register = (params) => {
  return httpVu('POST', 'auth/registration', params);
};

export const confirmRegistration = (token) => {
  return httpVu('PUT', `auth/confirmation/${token}`);
};

/**
 * Accept Invitation by Token
 * @param data
 * @returns {*}
 */
export const authAcceptInvitation = (data) => {
  // Data includes the token.
  return httpVu('POST', `auth/accept`, data);
};

/**
 * Validate Invitation Token
 * @param token
 * @returns {*}
 */
export const validateInvitationToken = (token) => {
  return httpVu('GET', `auth/accept/${token}`);
};

/**
 * Accept Activation By Token
 * @param token
 * @param data
 * @returns {*}
 */
export const authAcceptActivationByToken = (token, data) => {
  return httpVu('POST', `auth/activate/${token}`, data);
};

/**
 * Validate Activate Token
 * @param token
 * @returns {company}
 */
export const validateActivationToken = (token) => {
  return httpVu('GET', `auth/activate/${token}`);
};

/**
 * Accept Activation by Company ID
 * @param companyId
 * @param data
 * @returns {*}
 */
export const authAcceptActivationById = (companyId, data) => {
  return httpVu('POST', `auth/activate/c/${companyId}`, data);
};

/**
 * Send Activation Email to CB
 * @param cbId
 * @returns {*}
 */
export const sendCertificationBodyActivation = (cbId) => {
  return http('POST', `activation/cb/${cbId}`);
};

/**
 * Send Activation Email to Company (CE)
 * @param companyId
 * @returns {*}
 */
export const sendBranchActivation = (companyId) => {
  return http('POST', `activation/branch/${companyId}`);
};

export const sendBulkActivation = () => {
  return http('POST', `activation/ce/bulk`);
};

export const getActivationHistory = (companyType, companyId, queries) => {
  return http('GET', `activation/branch/${companyId}/${companyType}`, queries);
};

// Verify Captcha
export const verifyAuthCaptcha = (captchaType, data) => {
  return httpVu('POST', `auth/captcha?type=${captchaType}`, data);
};

export const resendAuthEmailGuard = (data) => {
  return httpVu('POST', `/auth/2fa/eg-resend`, data);
};

export const postAuthCaptchaVerify = (data) => {
  return httpVu('POST', '/auth/captcha/verify', data);
};

export const postAuthEmail = (data) => {
  return httpVu('POST', '/auth/email', data);
};

export const postAuthPassword = (data) => {
  return httpVu('POST', '/auth/password', data);
};

export const postAuthEmailGuardSend = (data) => {
  return httpVu('POST', '/auth/email-guard/send', data);
};

export const postAuthEmailGuardAlternateSend = (data) => {
  return httpVu('POST', '/auth/email-guard/alternate/send', data);
};

export const postAuthEmailGuardVerify = (data) => {
  return httpVu('POST', '/auth/email-guard/verify', data);
};

export const postAuthAuthenticatorVerify = (data) => {
  return httpVu('POST', '/auth/authenticator/verify', data);
};

export const postAuthForgotPassword = (data) => {
  return httpVu('POST', '/auth/forgot', data);
};

export const getAuthResetPasswordCheck = (data) => {
  return httpVu('POST', 'auth/reset/password/check', data);
};

export const getAuthPasswordBreachCheck = (data) => {
  return httpVu('POST', 'auth/password/breach/check', data);
};

export const postAuthRegistration = (data) => {
  return httpVu('POST', 'auth/registration', data);
};

export const postAuthResendConfirmEmail = (data) => {
  return httpVu('POST', 'auth/resend-confirm-email', data);
};

export const putAuthConfirmation = (data) => {
  return httpVu('PUT', 'auth/confirmation', data);
};

export const getAuthActivateToken = (token) => {
  return httpVu('GET', `auth/activate/${token}`);
};

export const postAuthActivateToken = (token, formData) => {
  return httpVu('POST', `auth/activate/${token}`, formData);
};

export const postAuthActivateTokenToken = (token, formData) => {
  return httpVu('POST', `auth/activate/token/${token}`, formData);
};
