import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import {ROUTE_ACCESS_RULES} from 'configs';

import {IS_STAGE_DEV, ROUTE_ENV_RULES} from 'configs';
import {AuthConsumer} from 'contexts/AuthContext';
import {ACCOUNT_ROLES, COMPANY_TYPES, SIGNED_AGREEMENTS_CHECKER} from 'configs';

const PublicRoute = ({
  env,
  path,
  routes,
  component: Component,
  cbTemplate,
  certTemplate,
  ...rest
}) => {
  const isDev = env === ROUTE_ENV_RULES.development;
  const isBlocked = isDev && !IS_STAGE_DEV;

  return (
    <Route
      {...rest}
      path={path}
      render={(props) => {
        // Check Environment
        if (isBlocked) return <Redirect to="/coming" />;

        return (
          <Component
            {...props}
            routes={routes}
            cbTemplate={cbTemplate}
            certTemplate={certTemplate}
          />
        );
      }}
    />
  );
};

const PrivateRoute = ({
  env,
  isAuth,
  isConsented,
  isEmailConfirmed,
  isNotSignedAgreement,
  path,
  routes,
  component: Component,
  cbTemplate,
  certTemplate,
  ...rest
}) => {
  const isDev = env === ROUTE_ENV_RULES.development;
  const isBlocked = isDev && !IS_STAGE_DEV;

  return (
    <Route
      {...rest}
      render={(props) => {
        // 1.  Check Authentication
        if (!isAuth) {
          return <Redirect to="/" />;
        }

        const isNotPageException = [
          '/setup',
          '/consents',
          '/logout',
          '/e-signature',
        ].every((p) => props.match.url !== p);

        // 2.  Check Consents
        if (!isConsented && isNotPageException) {
          return <Redirect to="/consents" />;
        }

        // 3. Check Email Confirmation
        if (!isEmailConfirmed && isNotPageException) {
          return <Redirect to="/setup" />;
        }

        if (isNotSignedAgreement && isNotPageException) {
          return <Redirect to="/e-signature" />;
        }

        // Check Environment
        if (isBlocked) return <Redirect to="/coming" />;

        return (
          <Component
            {...props}
            routes={routes}
            cbTemplate={cbTemplate}
            certTemplate={certTemplate}
          />
        );
      }}
    />
  );
};

const GuestRoute = ({env, isAuth, routes, component: Component, ...rest}) => {
  const isDev = env === ROUTE_ENV_RULES.development;
  const isBlocked = isDev && !IS_STAGE_DEV;

  return (
    <Route
      {...rest}
      render={(props) => {
        // 1. Check Authentication
        if (isAuth) {
          return <Redirect to="/dashboard" />;
        }

        // Check Environment
        if (isBlocked) return <Redirect to="/coming" />;

        return <Component {...props} routes={routes} />;
      }}
    />
  );
};

const renderRoutes = (routes) => {
  // A <Switch> renders the first child <Route> that matches. A <Route> with no path always matches.
  return (
    <AuthConsumer>
      {({
        account,
        isAuth,
        isConsented,
        isMainAccount,
        cbTemplate,
        certTemplate,
      }) => {
        return (
          // Filter routes by access rules
          <Switch>
            {routes.map(({env, access, ...rest}, i) => {
              switch (access) {
                case ROUTE_ACCESS_RULES.private: {
                  // Authenticated User Only
                  return (
                    <PrivateRoute
                      key={i}
                      env={env}
                      isEmailConfirmed={
                        isAuth && account.account_confirm_email === 1
                      }
                      isAuth={isAuth}
                      isNotSignedAgreement={
                        SIGNED_AGREEMENTS_CHECKER &&
                        isAuth &&
                        account.company.company_type === COMPANY_TYPES.cb &&
                        account.company.is_default === 1 &&
                        account.account_role === ACCOUNT_ROLES.super_admin &&
                        !account.company.signed_cb_data_management_agreement
                      }
                      isConsented={isConsented}
                      cbTemplate={cbTemplate}
                      certTemplate={certTemplate}
                      {...rest}
                    />
                  );
                }
                // Unauthenticated User Only
                case ROUTE_ACCESS_RULES.guest: {
                  return (
                    <GuestRoute key={i} env={env} isAuth={isAuth} {...rest} />
                  );
                }

                // Open to Everyone
                default:
                case ROUTE_ACCESS_RULES.public: {
                  return (
                    <PublicRoute
                      key={i}
                      env={env}
                      cbTemplate={cbTemplate}
                      certTemplate={certTemplate}
                      {...rest}
                    />
                  );
                }
              }
            })}

            <Route render={() => <Redirect to="/not-found" />} />
          </Switch>
        );
      }}
    </AuthConsumer>
  );
};

export default renderRoutes;
