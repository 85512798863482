import React from 'react';
import Loadable from 'components/Loadable';
import {DOCUMENTATION_LANDING_URL} from 'configs';
import {Replace} from 'routes';

const dashboard = {
  path: '/dashboard',
  component: Loadable({
    loader: () => import('../views/dashboard'),
  }),
  routes: [
    {
      exact: true,
      path: '/dashboard',
      component: Loadable({
        loader: () => import('../views/dashboard/overview'),
      }),
    },
    {
      path: '/dashboard/user-guide',
      component: () => <Replace to={DOCUMENTATION_LANDING_URL} />,
    },
  ],
};

export default dashboard;
