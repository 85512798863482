import React from 'react';
import {Link} from 'react-router-dom';
import {Nav, Button} from 'reactstrap';

import PrivateNavUser from './PrivateNavUser';
import PrivateNavNotification from './PrivateNavNotification';

function PrivateNav(props) {
  const {dark} = props;

  return (
    <>
      <Button
        to="/"
        tag={Link}
        color="dark"
        className="text-uppercase mx-3 py-2 px-3 rounded"
        style={{letterSpacing: '3px', outline: 'none'}}
      >
        Sandbox
      </Button>
      <Nav className="align-items-lg-center ml-auto flex-row" navbar>
        <PrivateNavNotification dark={dark} />

        <PrivateNavUser />
      </Nav>
    </>
  );
}

export default PrivateNav;
