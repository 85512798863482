import React from 'react';
import {Redirect} from 'react-router-dom';
import Error from 'views/errors';

import activate from './activate';
import contactSupport from './contact-support';
import elinks from './elinks';
import accreditationBody from './accreditation-body';
import certificationBody from './certification-body';
import certifiedEntity from './certified-entity';
import certification from './certification';
import branches from './branches';
import ceProfile from './ce-profile';
import certificationBodies from './certification-bodies';
import certifications from './certifications';
import certifiedEntities from './certified-entities';
// import certifiedMarketplace from './certified-marketplace';
import consents from './consents';
import dashboard from './dashboard';
// import eSignature from './e-signature';
import forgot from './forgot';
import login from './login';
import logout from './logout';
import reset from './reset';
import settings from './settings';
import setup from './setup';
import users from './users';
import importManagement from './import-management';
import importSettings from './import-settings';
import cbUpload from './cb-upload';
import standards from './standards';
import abStandards from './ab-standards';
import cbStandards from './cb-standards';
import abProfile from './ab-profile';
import cbProfile from './cb-profile';
import {
  DOCUMENTATION_LANDING_URL,
  ROUTE_ACCESS_RULES,
  // SIGNED_AGREEMENTS_CHECKER,
} from 'configs';

/*
Example:
{
env: ROUTE_ENV_RULES.development,
access: ROUTE_ACCESS_RULES.public,
...certification,
},
*/

export const Replace = ({to}) => {
  React.useEffect(() => {
    window.location.replace(to);
  }, []);
  return null;
};

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/login" />,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...login,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...contactSupport,
  },
  {
    access: ROUTE_ACCESS_RULES.guest,
    ...forgot,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...activate,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...elinks,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...accreditationBody,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...certificationBody,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...certifiedEntity,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...certification,
  },
  {
    access: ROUTE_ACCESS_RULES.public,
    ...reset,
  },
  {access: ROUTE_ACCESS_RULES.private, ...branches},
  {access: ROUTE_ACCESS_RULES.private, ...ceProfile},
  {access: ROUTE_ACCESS_RULES.private, ...certificationBodies},
  {access: ROUTE_ACCESS_RULES.private, ...certifications},
  {access: ROUTE_ACCESS_RULES.private, ...certifiedEntities},
  {access: ROUTE_ACCESS_RULES.private, ...consents},
  // SIGNED_AGREEMENTS_CHECKER && {
  //   access: ROUTE_ACCESS_RULES.private,
  //   ...eSignature,
  // },
  {access: ROUTE_ACCESS_RULES.private, ...dashboard},
  {access: ROUTE_ACCESS_RULES.private, ...logout},
  {access: ROUTE_ACCESS_RULES.private, ...settings},
  {access: ROUTE_ACCESS_RULES.private, ...setup},
  {access: ROUTE_ACCESS_RULES.private, ...standards},
  {access: ROUTE_ACCESS_RULES.private, ...users},
  {access: ROUTE_ACCESS_RULES.private, ...importManagement},
  {access: ROUTE_ACCESS_RULES.private, ...cbUpload},
  {access: ROUTE_ACCESS_RULES.private, ...importSettings},
  {access: ROUTE_ACCESS_RULES.private, ...abStandards},
  {access: ROUTE_ACCESS_RULES.private, ...cbStandards},
  {access: ROUTE_ACCESS_RULES.private, ...abProfile},
  {access: ROUTE_ACCESS_RULES.private, ...cbProfile},
  {
    access: ROUTE_ACCESS_RULES.private,
    path: '/user-guide',
    component: () => <Replace to={DOCUMENTATION_LANDING_URL} />,
  },
  {
    exact: true,
    path: '/error/:code',
    component: Error,
  },
  {
    exact: true,
    path: '/not-found',
    component: Error,
  },
  {
    component: () => <Redirect to="/not-found" />,
  },
].filter((v) => v);

export default routes;
