const enable = (value) => value !== '0';

// Activation
export const ENABLE_ACTIVATION = enable(
  process.env.REACT_APP_ENABLE_ACTIVATION
);

// Invitation
export const ENABLE_INVITATION = enable(
  process.env.REACT_APP_ENABLE_INVITATION
);

// Dashboard
export const ENABLE_PROGRESS = enable(process.env.REACT_APP_ENABLE_PROGRESS);

// CB
export const ENABLE_CB = enable(process.env.REACT_APP_ENABLE_CB);
export const ENABLE_CB_CREATE = enable(process.env.REACT_APP_ENABLE_CB_CREATE);
export const ENABLE_CB_READ = enable(process.env.REACT_APP_ENABLE_CB_READ);
export const ENABLE_CB_UPDATE = enable(process.env.REACT_APP_ENABLE_CB_UPDATE);
export const ENABLE_CB_DELETE = enable(process.env.REACT_APP_ENABLE_CB_DELETE);

// CB - Standards
export const ENABLE_CB_STANDARDS = enable(
  process.env.REACT_APP_ENABLE_CB_STANDARDS
);
export const ENABLE_CB_STANDARDS_CREATE = enable(
  process.env.REACT_APP_ENABLE_CB_STANDARDS_CREATE
);
export const ENABLE_CB_STANDARDS_READ = enable(
  process.env.REACT_APP_ENABLE_CB_STANDARDS_READ
);
export const ENABLE_CB_STANDARDS_UPDATE = enable(
  process.env.REACT_APP_ENABLE_CB_STANDARDS_UPDATE
);
export const ENABLE_CB_STANDARDS_DELETE = enable(
  process.env.REACT_APP_ENABLE_CB_STANDARDS_DELETE
);

// CB - Sectors
export const ENABLE_CB_SECTORS = enable(
  process.env.REACT_APP_ENABLE_CB_SECTORS
);
export const ENABLE_CB_SECTORS_CREATE = enable(
  process.env.REACT_APP_ENABLE_CB_SECTORS_CREATE
);
export const ENABLE_CB_SECTORS_READ = enable(
  process.env.REACT_APP_ENABLE_CB_SECTORS_READ
);
export const ENABLE_CB_SECTORS_UPDATE = enable(
  process.env.REACT_APP_ENABLE_CB_SECTORS_UPDATE
);
export const ENABLE_CB_SECTORS_DELETE = enable(
  process.env.REACT_APP_ENABLE_CB_SECTORS_DELETE
);

// CB - Office Directories
export const ENABLE_CB_OFFICE = enable(process.env.REACT_APP_ENABLE_CB_OFFICE);
export const ENABLE_CB_OFFICE_CREATE = enable(
  process.env.REACT_APP_ENABLE_CB_OFFICE_CREATE
);
export const ENABLE_CB_OFFICE_READ = enable(
  process.env.REACT_APP_ENABLE_CB_OFFICE_READ
);
export const ENABLE_CB_OFFICE_UPDATE = enable(
  process.env.REACT_APP_ENABLE_CB_STANDARDS_UPDATE
);
export const ENABLE_CB_OFFICE_DELETE = enable(
  process.env.REACT_APP_ENABLE_CB_OFFICE_DELETE
);

// CB - Country/Economy
export const ENABLE_CB_COUNTRY = enable(process.env.REACT_APP_ENABLE_CB_OFFICE);
export const ENABLE_CB_COUNTRY_CREATE = enable(
  process.env.REACT_APP_ENABLE_CB_COUNTRY_CREATE
);
export const ENABLE_CB_COUNTRY_READ = enable(
  process.env.REACT_APP_ENABLE_CB_COUNTRY_READ
);
export const ENABLE_CB_COUNTRY_UPDATE = enable(
  process.env.REACT_APP_ENABLE_CB_COUNTRY_UPDATE
);
export const ENABLE_CB_COUNTRY_DELETE = enable(
  process.env.REACT_APP_ENABLE_CB_COUNTRY_DELETE
);

// Standards - AB Standards
export const ENABLE_AB_STANDARDS = enable(
  process.env.REACT_APP_ENABLE_AB_STANDARDS
);
export const ENABLE_AB_STANDARDS_CREATE = enable(
  process.env.REACT_APP_ENABLE_AB_STANDARDS_CREATE
);
export const ENABLE_AB_STANDARDS_READ = enable(
  process.env.REACT_APP_ENABLE_AB_STANDARDS_READ
);
export const ENABLE_AB_STANDARDS_UPDATE = enable(
  process.env.REACT_APP_ENABLE_AB_STANDARDS_UPDATE
);
export const ENABLE_AB_STANDARDS_DELETE = enable(
  process.env.REACT_APP_ENABLE_AB_STANDARDS_DELETE
);

// Standards - Technical Sectors
export const ENABLE_AB_SECTORS = enable(
  process.env.REACT_APP_ENABLE_AB_SECTORS
);
export const ENABLE_AB_SECTORS_CREATE = enable(
  process.env.REACT_APP_ENABLE_AB_SECTORS_CREATE
);
export const ENABLE_AB_SECTORS_READ = enable(
  process.env.REACT_APP_ENABLE_AB_SECTORS_READ
);
export const ENABLE_AB_SECTORS_UPDATE = enable(
  process.env.REACT_APP_ENABLE_AB_SECTORS_UPDATE
);
export const ENABLE_AB_SECTORS_DELETE = enable(
  process.env.REACT_APP_ENABLE_AB_SECTORS_DELETE
);

// DM - File Upload
export const ENABLE_DM_FILE_UPLOAD = enable(
  process.env.REACT_APP_ENABLE_DM_FILE_UPLOAD
);
// DM - FTP Upload
export const ENABLE_DM_FTP = enable(process.env.REACT_APP_ENABLE_DM_FTP);
// DM - API
export const ENABLE_DM_API = enable(process.env.REACT_APP_ENABLE_DM_API);
// DM - Data Mapping
export const ENABLE_DM_DATA_MAPPING = enable(
  process.env.REACT_APP_ENABLE_DM_DATA_MAPPING
);
// DM - Field Mapping
export const ENABLE_DM_FIELD_MAPPING = enable(
  process.env.REACT_APP_ENABLE_DM_FIELD_MAPPING
);

// Enquiries
export const ENABLE_ENQUIRIES = enable(process.env.REACT_APP_ENABLE_ENQUIRIES);

///
/// Branches
export const ENABLE_BRANCHES = enable(process.env.REACT_APP_ENABLE_BRANCHES);

export const ENABLE_BRANCHES_CREATE = enable(
  process.env.REACT_APP_ENABLE_BRANCHES_CREATE
);
export const ENABLE_BRANCHES_READ = enable(
  process.env.REACT_APP_ENABLE_BRANCHES_READ
);
export const ENABLE_BRANCHES_UPDATE = enable(
  process.env.REACT_APP_ENABLE_BRANCHES_UPDATE
);
export const ENABLE_BRANCHES_DELETE = enable(
  process.env.REACT_APP_ENABLE_BRANCHES_DELETE
);

///
/// Users
export const ENABLE_USERS = enable(process.env.REACT_APP_ENABLE_USERS);

export const ENABLE_USERS_CREATE = enable(
  process.env.REACT_APP_ENABLE_USERS_CREATE
);
export const ENABLE_USERS_READ = enable(
  process.env.REACT_APP_ENABLE_USERS_READ
);
export const ENABLE_USERS_UPDATE = enable(
  process.env.REACT_APP_ENABLE_USERS_UPDATE
);
export const ENABLE_USERS_DELETE = enable(
  process.env.REACT_APP_ENABLE_USERS_DELETE
);

///
/// AB Profile

// AB Profile - Account
export const ENABLE_AB_PROFILE_ACCOUNT = enable(
  process.env.REACT_APP_ENABLE_AB_PROFILE_ACCOUNT
);

// AB Profile - Office Directories
export const ENABLE_AB_PROFILE_DIRECTORIES = enable(
  process.env.REACT_APP_ENABLE_AB_PROFILE_DIRECTORIES
);

///
/// Settings

// Settings - User
export const ENABLE_SETTINGS_USER = enable(
  process.env.REACT_APP_ENABLE_SETTINGS_USER
);

// Settings - Security
export const ENABLE_SETTINGS_SECURITY = enable(
  process.env.REACT_APP_ENABLE_SETTINGS_SECURITY
);

// Settings - Notifications
export const ENABLE_SETTINGS_NOTIFICATIONS = enable(
  process.env.REACT_APP_ENABLE_SETTINGS_NOTIFICATIONS
);

// Settings - Accounts
export const ENABLE_SETTINGS_ACCOUNTS = enable(
  process.env.REACT_APP_ENABLE_SETTINGS_ACCOUNT
);

// Certified Entities
export const ENABLE_CE = enable(process.env.REACT_APP_ENABLE_CE);
export const ENABLE_CE_CREATE = enable(process.env.REACT_APP_ENABLE_CE_CREATE);
export const ENABLE_CE_READ = enable(process.env.REACT_APP_ENABLE_CE_READ);
export const ENABLE_CE_UPDATE = enable(process.env.REACT_APP_ENABLE_CE_UPDATE);
export const ENABLE_CE_DELETE = enable(process.env.REACT_APP_ENABLE_CE_UPDATE);

// Certifications
export const ENABLE_CERT = enable(process.env.REACT_APP_ENABLE_CERT);
export const ENABLE_CERT_CREATE = enable(
  process.env.REACT_APP_ENABLE_CERT_CREATE
);
export const ENABLE_CERT_READ = enable(process.env.REACT_APP_ENABLE_CERT_READ);
export const ENABLE_CERT_UPDATE = enable(
  process.env.REACT_APP_ENABLE_CERT_UPDATE
);
export const ENABLE_CERT_DELETE = enable(
  process.env.REACT_APP_ENABLE_CERT_DELETE
);

// Certifications - Sites
export const ENABLE_CERT_SITES = enable(
  process.env.REACT_APP_ENABLE_CERT_SITES
);
export const ENABLE_CERT_SITES_CREATE = enable(
  process.env.REACT_APP_ENABLE_CERT_SITES_CREATE
);
export const ENABLE_CERT_SITES_READ = enable(
  process.env.REACT_APP_ENABLE_CERT_SITES_READ
);
export const ENABLE_CERT_SITES_UPDATE = enable(
  process.env.REACT_APP_ENABLE_CERT_SITES_UPDATE
);
export const ENABLE_CERT_SITES_DELETE = enable(
  process.env.REACT_APP_ENABLE_CERT_SITES_DELETE
);

// Certification Body Profile
export const ENABLE_CB_PROFILE = enable(
  process.env.REACT_APP_ENABLE_CB_PROFILE
);

// Certified Entity Profile
export const ENABLE_CE_PROFILE = enable(
  process.env.REACT_APP_ENABLE_CE_PROFILE
);
