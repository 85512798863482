import React from 'react';
import {Link} from 'react-router-dom';
import {VIEWPORT_WIDTHS} from 'configs';

import {useViewport} from 'contexts/ViewportContext';
import {
  Nav,
  NavItem,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from 'reactstrap';
import {IconFA} from 'components/Icons';

PublicNav.defaultProps = {
  dark: false,
};

function PublicNav(props) {
  const {dark} = props;
  const {size} = useViewport();

  const isMobile = VIEWPORT_WIDTHS[size] < VIEWPORT_WIDTHS.xl;

  return (
    <>
      <Button
        to="/"
        tag={Link}
        color="dark"
        className="text-uppercase mx-3 py-2 px-3 rounded"
        style={{letterSpacing: '3px', outline: 'none'}}
      >
        Sandbox
      </Button>
      {isMobile ? (
        <UncontrolledDropdown className="ml-auto">
          <DropdownToggle
            color={dark ? 'light' : 'primary'}
            className="rounded"
            outline
          >
            <IconFA name="bars" />
          </DropdownToggle>

          <DropdownMenu right>
            <DropdownItem divider />

            <div className="px-2">
              <Button
                tag={Link}
                color="primary"
                outline
                to="/login"
                className="rounded"
                block
              >
                Log In
              </Button>
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>
      ) : (
        <>
          <Nav navbar className="ml-auto">
            <NavItem>
              <Button
                tag={Link}
                outline
                to="/login"
                color={dark ? 'light' : 'primary'}
                className="rounded"
              >
                Log In
              </Button>
            </NavItem>
          </Nav>
        </>
      )}
    </>
  );
}

export default PublicNav;
