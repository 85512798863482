import React from 'react';
import {Link} from 'react-router-dom';
import {get} from 'lodash/object';
import {COMPANY_TYPE} from 'configs/types';
import {AuthConsumer} from 'contexts/AuthContext';

import {
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import {ImageAvatar} from 'components/Images';
import {IconFA} from 'components/Icons';

class PrivateNavUser extends React.Component {
  render() {
    return (
      <AuthConsumer>
        {({companyType, account, isDefault}) => {
          const entityId = get(account, 'entity_id', '');

          let liveProfile;

          switch (companyType) {
            default:
              liveProfile = null;
              break;
            case COMPANY_TYPE.ab:
              liveProfile = `/accreditation-body/${entityId}`;
              break;
            case COMPANY_TYPE.cb:
              liveProfile = `/certification-body/${entityId}`;
              break;
            case COMPANY_TYPE.ce:
              liveProfile = `/certified-entity/${entityId}`;

              break;
          }

          return (
            <UncontrolledDropdown nav>
              <DropdownToggle nav className="p-0">
                <ImageAvatar
                  image={account.account_image}
                  name={
                    account.account_first_name + ' ' + account.account_last_name
                  }
                  size="40"
                />
              </DropdownToggle>

              <DropdownMenu right>
                <DropdownItem tag={Link} to="/dashboard">
                  <IconFA name="dashboard" className="mr-1" />
                  Dashboard
                </DropdownItem>

                <DropdownItem divider />

                {liveProfile && (
                  <React.Fragment>
                    <DropdownItem tag="a" href={liveProfile} target="_blank">
                      <IconFA name="bolt" className="mr-1" />
                      Live Profile
                    </DropdownItem>

                    <DropdownItem divider />
                  </React.Fragment>
                )}

                <DropdownItem header>Settings</DropdownItem>

                <DropdownItem tag={Link} to="/settings/user-profile">
                  <IconFA name="user-circle-o" className="mr-1" />
                  User Settings
                </DropdownItem>

                {(companyType === COMPANY_TYPE.ab ||
                  companyType === COMPANY_TYPE.cb) && (
                  <DropdownItem
                    tag={Link}
                    to={
                      isDefault
                        ? '/branches' //hq cb/ab account
                        : '/settings/account' //non-hq cb/ab account
                    }
                  >
                    <IconFA name="building-o" className="mr-1" />
                    Account Settings
                  </DropdownItem>
                )}

                <DropdownItem divider />

                <DropdownItem tag={Link} to="/logout">
                  <IconFA name="sign-out" className="mr-1" />
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        }}
      </AuthConsumer>
    );
  }
}

export default PrivateNavUser;
